import {
	AddToCartInput,
	AddToCartPricedOption,
	Maybe,
	OptionSelectionTypeEnum,
	ProductOptionSourceType,
	RecommendedGroupSelectionFieldsFragment,
	RecommendedOptionSelectionFieldsFragment,
	RequiredGroupSelectionFieldsFragment,
	RequiredOptionSelectionFieldsFragment,
	VariationGroupSelectionFieldsFragment,
	VariationOptionSelectionFieldsFragment
} from '../../../__generated__/graphql-client-types';
import { AddToCartAnalyticsInputData, ProductFamilyInputData } from '../../../hooks/add-to-cart/add-to-cart.hooks';
import { Selection } from '../../../types/configuration.types';
import { ProductFamily } from '../../../types/product.types';
import { ANALYTICS_PAGE_NAMES } from '../../analytics/analytics.helper';
import { ADD_TO_CART_BUTTON_TYPE } from '../add-to-cart-button/add-to-cart-button.helper';
import { changeOption } from './product-option.helper';

export const DEFAULT_SELECTION: Selection = {
	id: 1,
	price: 0,
	quantity: 1,
	total: 0,
	initialTotal: 0,
	isBundle: false,
	groups: [],
	variantId: 0
};

export type CombinedOptionGroup =
	| RequiredGroupSelectionFieldsFragment
	| VariationGroupSelectionFieldsFragment
	| RecommendedGroupSelectionFieldsFragment;

export type OptionSelection =
	| RequiredOptionSelectionFieldsFragment
	| VariationOptionSelectionFieldsFragment
	| RecommendedOptionSelectionFieldsFragment;

export const isRequiredGroupSelection = (args: CombinedOptionGroup): args is RequiredGroupSelectionFieldsFragment => {
	return args.type === 'REQUIRED';
};
export const isVariationGroupSelection = (args: CombinedOptionGroup): args is VariationGroupSelectionFieldsFragment => {
	return args.type === 'VARIATION';
};
export const isRecommendedGroupSelection = (args: CombinedOptionGroup): args is RecommendedGroupSelectionFieldsFragment => {
	return args.type === 'RECOMMENDED';
};

export const isRequiredOptionSelection = (args: OptionSelection): args is RequiredOptionSelectionFieldsFragment => {
	return args.__typename === 'RequiredOptionSelection';
};
export const isVariationOptionSelection = (args: OptionSelection): args is VariationOptionSelectionFieldsFragment => {
	return args.__typename === 'VariationOptionSelection';
};
export const isRecommendedOptionSelection = (args: OptionSelection): args is RecommendedOptionSelectionFieldsFragment => {
	return args.__typename === 'RecommendedOptionSelection';
};

export const isConfigurationValidV2 = (selection: Selection): boolean => {
	return selection.groups.every((group) => group.valid);
};
export const isNestedOptionSelected = (
	group: RequiredGroupSelectionFieldsFragment,
	option: RequiredOptionSelectionFieldsFragment
): boolean => {
	return group.selectionType === 'NESTED' && option.selected && Boolean(option.quantity && option.quantity > 0);
};

export const filterOptionsBy = (
	predicate: (group: RequiredGroupSelectionFieldsFragment, option: RequiredOptionSelectionFieldsFragment) => boolean,
	selection?: Selection
): RequiredOptionSelectionFieldsFragment[] => {
	return selection && selection.groups && Array.isArray(selection.groups)
		? selection.groups.flatMap((group) => group.options.filter((option) => predicate(group, option)))
		: [];
};

export const calculateTotalItems = (selection: Selection, initialValue?: number) => {
	return filterOptionsBy(isNestedOptionSelected, selection).reduce((total, item) => {
		total += item.quantity || 1;
		return total;
	}, initialValue || selection.quantity);
};

export const isOptionSelected = (option: OptionSelection): boolean => {
	const INDEPENDENT_TYPE: OptionSelectionTypeEnum = 'INDEPENDENT';
	return (
		(isRequiredOptionSelection(option) &&
			((option.selected && option.selectionType !== INDEPENDENT_TYPE) ||
				Boolean(option.selectionType === INDEPENDENT_TYPE && option.value && option.value.length > 0))) ||
		(isVariationOptionSelection(option) && option.selected)
	);
};

export const updateSelectionTotal = (selection: Selection): Selection => {
	const grandTotal = selection.groups.reduce((total, group) => {
		total += group.options.reduce((subTotal, option) => {
			subTotal += isOptionSelected(option) ? option.price * (option.quantity || 1) : 0;
			return subTotal;
		}, 0);
		return total;
	}, selection.initialTotal || 0);
	return { ...selection, total: grandTotal };
};

export const byId = <T extends { id: number | string }>(current: T, given: T): boolean => current.id === given.id;

export const byOptionId = <T extends { optionId: number | string }>(current: T, given: T): boolean => current.optionId === given.optionId;

export const updateGroup = (selection: Selection, updatedGroup: RequiredGroupSelectionFieldsFragment): Selection => {
	return { ...selection, groups: selection.groups.map((group) => (byId(group, updatedGroup) ? updatedGroup : group)) };
};

const resetSingleSelectionOptions = (group: RequiredGroupSelectionFieldsFragment): RequiredGroupSelectionFieldsFragment => {
	return {
		...group,
		options: group.options.map((option) => (option.selectionType === 'SINGLE' ? { ...option, selected: false } : option))
	};
};

export const updateOption = (
	group: RequiredGroupSelectionFieldsFragment,
	updatedOption: RequiredOptionSelectionFieldsFragment
): RequiredGroupSelectionFieldsFragment => {
	group = updatedOption.selectionType === 'SINGLE' ? resetSingleSelectionOptions(group) : group;

	return {
		...group,
		options: group.options.map((current) => (byOptionId(current, updatedOption) ? { ...current, ...updatedOption } : current))
	};
};

export const updateSelection = (
	selection: Selection,
	updatedOption: RequiredOptionSelectionFieldsFragment,
	updatedGroup: RequiredGroupSelectionFieldsFragment
): Selection => {
	return updateSelectionTotal(updateGroup(selection, updateOption(updatedGroup, updatedOption)));
};

/**
 * Add to cart serialization helpers
 */
export type AddToCartSelectionV2<T> = {
	pricedOptions: T[];
	subItems?: AddToCartInput[];
};

const isOptionValueBased = (option: RequiredOptionSelectionFieldsFragment): boolean => {
	return Boolean(option.selectionType === 'INDEPENDENT' && option.value && option.value.length > 0);
};

export const isSelectedOptionValueBased = (option: RequiredOptionSelectionFieldsFragment): boolean => {
	const { selected } = option;
	const isValueBased = isOptionValueBased(option);
	return Boolean(option.optionId) && ((selected && !isValueBased) || isValueBased);
};

export const defaultSerializerV2 = <T extends AddToCartPricedOption>(option: RequiredOptionSelectionFieldsFragment): T | null => {
	const isValueBased = isOptionValueBased(option);
	if (option.selected && !isValueBased) {
		return { pricedOptionId: option.optionId, pricedOptionText: null } as T;
	} else if (isValueBased) {
		return {
			pricedOptionId: option.optionId,
			pricedOptionText: isValueBased ? option.value?.toString() : null
		} as T;
	}

	return null;
};

export const serializeNestedOption = (option: RequiredOptionSelectionFieldsFragment): AddToCartInput | null => {
	return option.selected && option.variantId ? { variantId: option.variantId, quantity: option.quantity } : null;
};

export const serializeSelectionV2 = <T extends AddToCartPricedOption>(
	selection: Selection,
	serializer: (option: RequiredOptionSelectionFieldsFragment) => T | null = defaultSerializerV2
): AddToCartSelectionV2<T> => {
	return selection.groups.reduce(
		(result, group) => {
			return {
				...result,
				...(group.selectionType === 'NESTED'
					? {
							subItems: [
								...(result.subItems || []),
								...(group.options.map(serializeNestedOption).filter(Boolean) as AddToCartInput[])
							]
					  }
					: {
							pricedOptions: [...result.pricedOptions, ...(group.options.map(serializer).filter(Boolean) as T[])]
					  })
			};
		},
		{ pricedOptions: [], subItems: [] } as AddToCartSelectionV2<T>
	);
};

/**
 * Add to cart Analytics helpers
 */
export const getConfigurableProductAnalyticsDataV2 = (
	params: Pick<AddToCartAnalyticsInputData, 'variantId' | 'quantity'>,
	productFamily: ProductFamilyInputData,
	pageName: ANALYTICS_PAGE_NAMES
): AddToCartAnalyticsInputData => {
	return {
		...params,
		...productFamily,
		pageName,
		link: 'Add to Cart',
		linkName: 'Add to Cart'
	};
};

export const generateATCAnalyticsDataV2 = (
	variantId: number,
	quantity: number,
	productFamily: Maybe<ProductFamily>,
	analyticsData?: AddToCartAnalyticsInputData,
	addToCartButtonType?: ADD_TO_CART_BUTTON_TYPE
) => {
	if (analyticsData) {
		return analyticsData;
	} else {
		const isSticky = addToCartButtonType === ADD_TO_CART_BUTTON_TYPE.STICKY;
		const analyticsPageName = isSticky ? ANALYTICS_PAGE_NAMES.PRODUCT_DETAILS_PAGE_STICKY : ANALYTICS_PAGE_NAMES.PRODUCT_DETAILS_PAGE;
		return getConfigurableProductAnalyticsDataV2({ variantId, quantity }, { productFamily }, analyticsPageName);
	}
};

export const findGroupBy = (
	selection: Selection,
	predicate: (current: RequiredGroupSelectionFieldsFragment) => boolean
): RequiredGroupSelectionFieldsFragment | null => {
	return selection.groups.find(predicate) || null;
};

export const findGroupSelectionById = (id: string, selection: Selection | undefined): RequiredGroupSelectionFieldsFragment | null =>
	selection ? findGroupBy(selection, (group) => group.id === id) : null;

export const updateGroupOption = (
	update,
	selectedNestedGroup: RequiredGroupSelectionFieldsFragment | null,
	selectedProduct?: RequiredOptionSelectionFieldsFragment | null,
	selection?: Selection
): void => {
	if (update && selectedProduct && selection && selectedNestedGroup) {
		update(changeOption({ ...selectedProduct, value: null }, false), { ...selectedNestedGroup });
	}
};

export const checkRecommendedType = (sources: ProductOptionSourceType[]): string => {
	const prepedSourceList: string[] = [];
	if (sources.length === 0) {
		prepedSourceList.push('mws-dynamic');
	}
	sources.forEach((source) => {
		if (source === 'REC_OPS') {
			prepedSourceList.push('rec-ops');
		} else if (source === 'MAY_WE_SUGGEST') {
			prepedSourceList.push('mws-manual');
		} else {
			prepedSourceList.push('mws-dynamic');
		}
	});
	return prepedSourceList.join(', ');
};

export const extractRecommendedOptionSourceType = (recommended: RecommendedGroupSelectionFieldsFragment[]): ProductOptionSourceType[] => {
	const sourceTypes: ProductOptionSourceType[] = ['DYNAMIC_YIELD', 'MAY_WE_SUGGEST', 'REC_OPS'];
	const availableSourceTypes: ProductOptionSourceType[] = [];
	recommended.forEach((option) => {
		if (sourceTypes.includes(option.source) && !availableSourceTypes.includes(option.source)) {
			availableSourceTypes.push(option.source);
		}
	});
	return availableSourceTypes;
};

export const findGroupByOption = (
	option: RecommendedOptionSelectionFieldsFragment,
	groups: RecommendedGroupSelectionFieldsFragment[]
): RecommendedGroupSelectionFieldsFragment | null => {
	const groupFound = groups.find((group) => {
		return group.options.find((item) => item.id === option.id);
	});
	return groupFound ?? null;
};

export const updateRecommendedGroup = (
	groups: RecommendedGroupSelectionFieldsFragment[],
	updatedGroup: RecommendedGroupSelectionFieldsFragment
): RecommendedGroupSelectionFieldsFragment[] => {
	return groups.map((group) => (byId(group, updatedGroup) ? updatedGroup : group));
};

export const updateRecommendedOption = (
	options: RecommendedOptionSelectionFieldsFragment[],
	updatedOption: RecommendedOptionSelectionFieldsFragment
): RecommendedOptionSelectionFieldsFragment[] => {
	return options.map((option) => (byId(option, updatedOption) ? updatedOption : option));
};
