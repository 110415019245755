import {
	AddToCartPricedOption,
	ProductWarrantiesQueryVariables,
	Maybe,
	CartItemFieldsFragment,
	MassAddItemInput,
	MassAddItemFieldsFragment,
	RequiredOptionSelectionFieldsFragment,
	ShippingMethodTypeEnum
} from '../../__generated__/graphql-client-types';
import { MASS_ADD_ERROR } from '../../constants/cart';
import { Cart, CartItem, SaveForLaterCartItem, ShippingOption } from '../../types/cart.types';
import { Customer } from '../../types/customer.types';
import { Selection as SelectionV2 } from '../../types/configuration.types';
import { formatPrice } from '../general-helper/general-helper';
import { defaultSerializer, Selection, serializeSelection } from '../product-helper/configuration/product-configuration.helper';
import { defaultSerializerV2, serializeSelectionV2 } from '../product-helper/configuration-v2/product-configuration.helper';

const MASS_ADD_ROW_INCREMENT = 5;

export type CartAddPricedOption = { pricedOptionPrice: number } & AddToCartPricedOption;

export type CartAddParams = {
	id: number; // cartItemId
	variantId: number;
	quantity: number;
	price: number;
	pricedOptions?: CartAddPricedOption[];
	bundleItems?: CartAddParams[];
	subItems?: CartAddParams[];
};

export type RecentCartItemChange = {
	id: number;
	variantId: number;
	quantity: number;
	totalPrice: number;
};

export type RecentCartChanges = {
	items: RecentCartItemChange[];
	totalItems: number;
	subtotal: number;
};

export type RecentCart = {
	changes: RecentCartChanges;
	capture: (nextSnapshot: CartAddParams) => void;
	reset: (initialSnapshot?: CartAddParams) => void;
};

const DEFAULT_RECENT_CHANGES: RecentCartChanges = { items: [], totalItems: 0, subtotal: 0 };

/**
 * Strategy that updates the current item properties with a new item for tracking recently cart item updates.
 */
const updateRecentCartItem = (currentItem: RecentCartItemChange, newItem: RecentCartItemChange): RecentCartItemChange => {
	return {
		...currentItem,
		quantity: currentItem.quantity + newItem.quantity,
		totalPrice: currentItem.totalPrice + newItem.totalPrice
	};
};

/**
 * Updates an existing recent cart item with a new one and returns the updated list.
 */
const updateListRecentCartItem = (
	items: RecentCartItemChange[],
	existingItem: RecentCartItemChange,
	newItem: RecentCartItemChange
): RecentCartItemChange[] => {
	return items.map((item) => (item.id === existingItem.id ? updateRecentCartItem(existingItem, newItem) : item));
};

/**
 * Calculate Totals for a given list of recent cart item changes
 */
export const calculateTotals = (items: RecentCartItemChange[]): Pick<RecentCartChanges, 'totalItems' | 'subtotal'> => {
	return items.reduce(
		(totals, item) => {
			totals.totalItems += item.quantity;
			totals.subtotal += item.totalPrice;
			return totals;
		},
		{ totalItems: 0, subtotal: 0 }
	);
};

/**
 * Update list of recent cart items by adding or modifying existing items with new information based on item ids.
 */
export const mergeCartItemChanges = (
	newRecentCartChanges: RecentCartChanges,
	currentRecentCartChanges: RecentCartChanges
): RecentCartItemChange[] => {
	return newRecentCartChanges.items.reduce((items, item) => {
		const existingItem = items.find((existing) => existing.variantId === item.variantId);
		return existingItem ? updateListRecentCartItem(items, existingItem, item) : [...items, item];
	}, currentRecentCartChanges.items);
};

export const mapWarrantyQueryVariables = (items: CartItem[]): ProductWarrantiesQueryVariables => {
	return {
		products: items
			.filter((item) => !item.isSubItem)
			.map((item) => ({
				modelNumber: item.modelNumber,
				title: item.title,
				price: item.price.unitPrice
			}))
	};
};

export const isLastItem = (id: number, items?: Maybe<CartItem[]>) => {
	if (!items) {
		return false;
	}

	return items[items.length - 1].id === id;
};

export const findCartItemById = (cartItems: CartItemFieldsFragment[], id: number): CartItemFieldsFragment | undefined => {
	return cartItems.find((cartItem) => cartItem.variant.id === id);
};

export const getRecentCartItemQuantity = (recentCartChanges: RecentCartChanges, cartItem: CartItemFieldsFragment): number => {
	const found = recentCartChanges.items.find((recentCartItem) => recentCartItem.id === cartItem.id);
	return found ? found.quantity : 1;
};

/**
 *  Maps a bundle item to a cartAddParam type.
 */
export const mapBundleItemToCartAddParams = (bundleItem: CartItemFieldsFragment): CartAddParams => {
	const { id, quantity } = bundleItem;
	const price = bundleItem.price.unitPrice;
	const variantId = bundleItem.variant.id;
	return { id, variantId, quantity, price };
};

/**
 * Creates a Cart Add instance from a configuration selection and the result of an add to cart operation.
 */
export const createCartAdd = (selection: Selection, cartItems: CartItemFieldsFragment[]): CartAddParams | null => {
	const { id, price, quantity, isABundle } = selection;
	if (isABundle) {
		const bundleItemsCartAdd = cartItems.map<CartAddParams>((cartItem) => ({ ...mapBundleItemToCartAddParams(cartItem), quantity }));
		return { id, variantId: id, price, quantity, bundleItems: bundleItemsCartAdd };
	}
	const found = findCartItemById(cartItems, selection.id) as CartItemFieldsFragment;
	const { pricedOptions, subItems } = serializeSelection(selection, (option) => {
		const pricedOption = defaultSerializer(option);
		return pricedOption ? { ...pricedOption, pricedOptionPrice: option.price } : null;
	});
	const subItemsCartAdd = cartItems
		.filter((cartItem) => cartItem.isSubItem)
		.map<CartAddParams | null>((cartItem) => {
			const subItemFound = subItems?.find((subItem) => subItem.variantId === cartItem.variant.id);
			return subItemFound
				? {
						id: cartItem.id,
						quantity: subItemFound?.quantity || 1,
						price: cartItem.price.unitPrice,
						variantId: cartItem.variant.id
				  }
				: null;
		})
		.filter(Boolean) as CartAddParams[];
	return found ? { id: found.id, variantId: id, price, quantity, pricedOptions, subItems: subItemsCartAdd } : null;
};

export const serializeSelectionPredicate = (
	option: RequiredOptionSelectionFieldsFragment
): ({ pricedOptionPrice: number } & AddToCartPricedOption) | null => {
	const pricedOption = defaultSerializerV2(option);
	return pricedOption ? { ...pricedOption, pricedOptionPrice: option.price } : null;
};
/**
 * Creates a Cart Add instance from a configuration selection and the result of an add to cart operation.
 * Once V2 is successful, the original function is going away
 */
export const createCartAddV2 = (selection: SelectionV2, cartItems: CartItemFieldsFragment[]): CartAddParams | null => {
	const { id, price, quantity, isBundle } = selection;
	if (isBundle) {
		const bundleItemsCartAdd = cartItems.map<CartAddParams>((cartItem) => ({ ...mapBundleItemToCartAddParams(cartItem), quantity }));
		return { id, variantId: id, price, quantity, bundleItems: bundleItemsCartAdd };
	}
	const found = findCartItemById(cartItems, selection.variantId) as CartItemFieldsFragment;
	const { pricedOptions, subItems } = serializeSelectionV2(selection, serializeSelectionPredicate);
	const subItemsCartAdd = cartItems
		.filter((cartItem) => cartItem.isSubItem)
		.map<CartAddParams | null>((cartItem) => {
			const subItemFound = subItems?.find((subItem) => subItem.variantId === cartItem.variant.id);
			return subItemFound
				? {
						id: cartItem.id,
						quantity: subItemFound?.quantity || 1,
						price: cartItem.price.unitPrice,
						variantId: cartItem.variant.id
				  }
				: null;
		})
		.filter(Boolean) as CartAddParams[];
	return found ? { id: found.id, variantId: id, price, quantity, pricedOptions, subItems: subItemsCartAdd } : null;
};

/**
 * Generates recent cart item changes commonly used to capture a snapshot of an individual product recently added to the cart.
 */
export const generateRecentCartItemChange = (cartAdd: CartAddParams): RecentCartItemChange => {
	const { id, variantId, quantity, price, pricedOptions } = cartAdd;
	const totalPrice =
		quantity *
		(pricedOptions?.reduce((total, option) => {
			total += option.pricedOptionPrice;
			return total;
		}, price) || price);
	return { id, variantId, quantity, totalPrice };
};

/**
 * Generates recent cart changes commonly used to capture a snapshot of most recent products added to the cart based on
 * an Add To Cart operation.
 */
export const generateRecentCartChanges = (cartAdd?: CartAddParams): RecentCartChanges => {
	if (cartAdd) {
		const items = [
			generateRecentCartItemChange(cartAdd),
			...(cartAdd.bundleItems?.map(generateRecentCartItemChange) || []),
			...(cartAdd.subItems?.map(generateRecentCartItemChange) || [])
		];
		return { ...calculateTotals(items), items };
	}
	return DEFAULT_RECENT_CHANGES;
};

/**
 * Checks if the cart has at least one "package" (a package is one item
 * that contains several individual items)
 */
export const cartHasPackage = (items?: Maybe<CartItem[]>) => {
	return items?.some((item) => item.containerType.toLowerCase() === 'package');
};

/**
 * The Mass Add Tool displays 5 rows of fields at a time (ie. clicking Add More will
 * add 5 more rows). This helper takes care of displaying 5 rows at a time as well
 * as including fields that are populated with data.
 */
export const getMassAddItems = (numGroups: number, items: MassAddItemInput[]): MassAddItemInput[] => {
	const itemsToAdd = numGroups * MASS_ADD_ROW_INCREMENT - items.length;

	if (itemsToAdd) {
		for (let i = 0; i < itemsToAdd; i++) {
			items.push({ searchTerm: '', quantity: 1 });
		}
	}

	return items;
};

/**
 * Returns a user friendly mass add error message based on the error code
 */
export const getMassAddErrorMessage = (item: MassAddItemFieldsFragment): string => {
	switch (item.errorCode) {
		case MASS_ADD_ERROR.NOT_FOUND:
			return `No product was found for "${item.searchTerm}"`;
		case MASS_ADD_ERROR.DUPLICATE:
			return `We found more than one product for "${item.searchTerm}"`;
		case MASS_ADD_ERROR.PRICED_OPTIONS:
			return `"${item.searchTerm}" is a configurable item`;
		default:
			return '';
	}
};

export const hasProp65Item = (items?: CartItem[] | null, customer?: Customer): boolean => {
	return (customer?.location?.state.abbreviation === 'CA' && items?.some((item) => item.prop65Warning)) || false;
};

const sortItemsByDate = (items: CartItem[]): CartItem[] => {
	return items.sort((a, b) => {
		const timeA = new Date(a.shipping?.deliveryDateLow).getTime();
		const timeB = new Date(b.shipping?.deliveryDateLow).getTime();
		return timeA - timeB;
	});
};

const isSubItemWarranty = (item: CartItem) => item.isSubItem && item.type.toLowerCase() === 'warranty';

/**
 * Group items by parcel or freight
 *
 * Subitem relationship removed except for warranties
 */
export const groupItems = (type: 'PARCEL' | 'FREIGHT', cart?: Cart | null) => {
	const isLTL = type === 'FREIGHT';
	const items = [...(cart?.items || [])];
	// Group by parcel or freight but keep warranty subItems in same group as parent
	const itemsByGroup = items.filter((item) => {
		if (isSubItemWarranty(item)) {
			const parentItem = items.find((parentItem) => parentItem.id === item.parentId);
			return parentItem?.isLTL === isLTL;
		}
		return item.isLTL === isLTL;
	});

	// Remove subItems that are warranties and add them back after sort
	const itemsWithoutWarranties = itemsByGroup.filter((item) => !isSubItemWarranty(item)).map((item) => ({ ...item, isSubItem: false }));

	const quickshipItems = sortItemsByDate(itemsWithoutWarranties.filter((item) => item.shipping?.isQuickShip));
	const estimatedDeliveryItems = sortItemsByDate(
		itemsWithoutWarranties.filter((item) => item.shipping?.message.toLowerCase().includes('estimated delivery'))
	);
	const otherItems = sortItemsByDate(
		itemsWithoutWarranties.filter(
			(item) => !item.shipping?.isQuickShip && !item.shipping?.message.toLowerCase().includes('estimated delivery')
		)
	);
	const sortedItems = [...quickshipItems, ...estimatedDeliveryItems, ...otherItems];

	// Add subItem warranties back
	itemsByGroup
		.filter((item) => isSubItemWarranty(item))
		.forEach((warranty) => {
			const parentIndex = sortedItems.findIndex((item) => item.id === warranty.parentId);
			sortedItems.splice(parentIndex + 1, 0, warranty);
		});

	return sortedItems;
};

export const getWhiteGloveCost = (cart?: Cart | null) => {
	const freightMethods = cart?.shippingDetails?.shippingOptions?.filter((option) => option.isLTL) || [];
	const whiteGloveShippingMethod = freightMethods.find((option) => option.isWhiteGlove);
	const freightShippingMethod = freightMethods.find((option) => !option.isWhiteGlove);

	return whiteGloveShippingMethod ? whiteGloveShippingMethod.price - (freightShippingMethod?.price || 0) : null;
};

export const getWarrantyText = (item: SaveForLaterCartItem, items: SaveForLaterCartItem[]) => {
	const warranty = items.find((currentItem) => currentItem.parentId === item.id && currentItem.type.toLowerCase() === 'warranty');

	return warranty ? `${warranty.title.substring(0, 6)} Protection Plan - ${formatPrice(warranty.price.unitPrice)}` : undefined;
};

/**
 * Validate shipping cost override amount
 *
 * Returns error message if amount is less than 0 or greater than shipping cost
 */
export const validateShippingCostOverride = (
	value: string,
	type: ShippingMethodTypeEnum,
	highCostShipping?: boolean,
	selectedShippingOption?: ShippingOption
) => {
	let error = '';
	const overrideAmount = Number(value);
	const isPositiveNumber = overrideAmount >= 0;

	if (overrideAmount < 0) {
		error = 'Cost override cannot be negative.';
	} else if ((!highCostShipping || type === 'PARCEL') && selectedShippingOption && overrideAmount > selectedShippingOption.price) {
		// Override cost can only be greater than shipping cost when high cost zip with freight
		error = 'Cost override cannot be greater than shipping cost.';
	} else if (!isPositiveNumber) {
		error = 'Please enter a valid number.';
	}

	return error;
};

/**
 * Validate the rebateTitle being present and return an cart item rebate an array
 *
 * Returns undefined if there's no rebates object or an empty array
 */
export const findRebateTitles = (items: CartItemFieldsFragment[]) => {
	return items
		?.map((item) => {
			return item?.rebates?.find((rebate) => rebate?.rebateTitle && rebate.rebateTitle?.length > 0);
		})
		.filter((rebateTitle) => rebateTitle !== undefined);
};
