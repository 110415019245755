/**
 * Validation Hooks
 */
import { LazyQueryResult, useLazyQuery } from '@apollo/client';
import { ValidateKeyCodeQuery, ValidateKeyCodeQueryVariables } from '../../../client/__generated__/graphql-client-types';
import { ERROR_INVALID_KEYCODE, ERROR_INVALID_KEYCODE_CHARACTERS, ERROR_INVALID_KEYCODE_LENGTH } from '../../constants/product';
import { areAllNaturalNumbers } from '../../helpers/general-helper/general-helper';
import { Validator, ValidatorHook } from '../../helpers/product-helper/configuration/product-option.helper';
import { PricedOptionValidation, ValidateOptionType } from '../../helpers/product-helper/priced-options.helper';
import { VALIDATE_KEYCODE } from '../../queries/product/priced-options/priced-options.queries';
import { internalKeycodeCheck } from '../../helpers/validator/validator.helper';

/**
 * Hook to validate key codes
 */
export const useValidateKeyCode = (validator: Validator): ValidatorHook => {
	const [execute, status] = useLazyQuery<ValidateKeyCodeQuery, ValidateKeyCodeQueryVariables>(VALIDATE_KEYCODE, {
		fetchPolicy: 'no-cache'
	});
	function validate(value: string, variantId: number, isInternal = false) {
		if (value.length === 0) {
			return { invalid: false, message: '' };
		}

		const areAllNumbers = areAllNaturalNumbers(value);

		// internal key code validation
		if (isInternal) {
			const result = internalKeycodeCheck(value, areAllNumbers);
			return result || { invalid: false, message: '' };
		}

		// consumer key code validation
		if (value.length !== 5 && value.length !== 6) {
			return { invalid: true, message: ERROR_INVALID_KEYCODE_LENGTH };
		}

		if (!areAllNumbers) {
			return { invalid: true, message: ERROR_INVALID_KEYCODE_CHARACTERS };
		}

		if (!status.loading && value !== status.variables?.keyCode) {
			void execute({ variables: { keyCode: value.toString(), variantId } });
		}

		if (status && status.data) {
			const invalid = status.data.validateKeyCode.code.toLowerCase() !== 'valid';
			return { invalid, message: invalid ? ERROR_INVALID_KEYCODE : '' };
		}

		return { invalid: false, message: '' };
	}
	return { validator, validate, status };
};

export type Validate = (value: string, validation: PricedOptionValidation) => PricedOptionValidation;

/**
 * Validates Key Code and returns Product Option Validation status
 * NOTE: Soon will be superseded by the above hook (This one is used in the product priced options component)
 */
export function usePricedOptionsValidateKeycode(
	option?: ValidateOptionType
): [Validate, LazyQueryResult<ValidateKeyCodeQuery, ValidateKeyCodeQueryVariables>] {
	const [validateKeyCode, status] = useLazyQuery<ValidateKeyCodeQuery, ValidateKeyCodeQueryVariables>(VALIDATE_KEYCODE);
	return [
		(value, validation): PricedOptionValidation => {
			void validateKeyCode({ variables: { keyCode: value, variantId: option?.variantId as number } });
			return { ...validation, isValid: false, message: '' };
		},
		status
	];
}
