/**
 * Product Option Client Helper
 */
import { LazyQueryResult } from '@apollo/client/react/types/types';
import { kebabCase, pascalCase } from '@fergdigitalcommerce/fergy-utilities';
import { logError } from 'fergy-core-react-logging';
import { RequiredPick } from '../../../../../@types/build';
import {
	AddToCartInput,
	ProductOptionFieldsFragment,
	ProductOptionGroupFieldsFragment,
	ProductOptionSourceType,
	ProductResourceFieldsFragment
} from '../../../__generated__/graphql-client-types';

import * as validatorHooks from '../../../hooks/validator/validator.hooks';
import { ProductResourceVariantCard } from '../../../types/product.types';
import { AnalyticsHelper } from '../../analytics/analytics.helper';

import { buildGTMSelectedVariation } from '../../analytics/gtm/gtm-utils.helper';
import { GroupSelection, isOptionSelected, OptionSelection, OptionSelectionType } from './product-configuration.helper';

const toggle = (option: OptionSelection): boolean => {
	if (option.type === OptionSelectionType.SINGLE) {
		// For single selection: if it's selected, it must remain selected (it forces returning always false only for this case).
		return !option.selected ? option.selected : false;
	} else if (option.type === OptionSelectionType.INDEPENDENT) {
		return true;
	}
	// Otherwise, return the current selected state subjected for toggling (Multiple Selection - selected/deselected).
	return option.selected;
};

export type AddToCartAction = (
	input: AddToCartInput,
	option: ProductOptionFieldsFragment,
	selectedVariant: ProductResourceVariantCard,
	source?: ProductOptionSourceType
) => Promise<void>;

export const areSelectionsAvailable = (selections?: OptionSelection[]): boolean =>
	typeof selections !== 'undefined' && selections.length > 0;

export const getOptionSelected = (option: ProductOptionFieldsFragment, selections?: OptionSelection[]): OptionSelection | null => {
	return selections?.find((selectedOption) => selectedOption.id === kebabCase(option.name)) || null;
};

export const isCurrentOptionSelected = (
	option: ProductOptionFieldsFragment,
	selections?: OptionSelection[],
	shouldToggle = false
): boolean => {
	if (areSelectionsAvailable(selections)) {
		const optionSelected = getOptionSelected(option, selections);
		if (optionSelected) {
			if (shouldToggle) {
				return toggle(optionSelected);
			} else {
				return optionSelected.selected;
			}
		} else {
			return option.selected;
		}
	}
	return option.selected;
};

export const changeOption = (option: ProductOptionFieldsFragment, selections?: OptionSelection[]): ProductOptionFieldsFragment => {
	return { ...option, selected: !isCurrentOptionSelected(option, selections, true) };
};

export const changeGroupValidity = (
	groupSelection: RequiredPick<Partial<GroupSelection>, 'id'>,
	option: ProductOptionFieldsFragment,
	optionSelections?: OptionSelection[]
): RequiredPick<Partial<GroupSelection>, 'id'> => {
	let valid = typeof groupSelection.valid !== 'undefined' ? groupSelection.valid : true;
	if (optionSelections) {
		if (option.type === 'TEXTBOX' && valid) {
			valid = optionSelections.some((optionSelection) => isOptionSelected(optionSelection));
		} else if (option.type === 'CHECKBOX' && !option.selected) {
			const allOptionsExceptCurrent = optionSelections.filter((optionSelection) => optionSelection.id !== kebabCase(option.name));
			valid = allOptionsExceptCurrent.some((optionSelection) => isOptionSelected(optionSelection));
		}
	}
	return { ...groupSelection, valid };
};

export const updateOptionResource = (
	resource: ProductResourceFieldsFragment,
	variant?: ProductResourceVariantCard,
	newQuantity = 1
): ProductResourceFieldsFragment => {
	return variant
		? {
				...resource,
				title: variant.title,
				status: variant.status,
				url: variant.url,
				modelNumber: variant.modelNumber,
				price: {
					...(resource.price ? resource.price : { discount: null, packaging: null, unitPrice: null }),
					current: variant.price
				},
				quantity: newQuantity
		  }
		: resource;
};

export const handleProductOptionClick = (
	option: ProductOptionFieldsFragment,
	group: ProductOptionGroupFieldsFragment,
	navigate: (url: string, replace?: boolean) => void,
	change: (currentOption: ProductOptionFieldsFragment, updatedGroupSelection?: Partial<GroupSelection>) => void
): void => {
	if (group.type === 'VARIATION' && option.resource?.url) {
		const uniqueId = Number(option.resource.url.split('=')[1]) || 0;
		AnalyticsHelper.track(buildGTMSelectedVariation(option.name, uniqueId)).catch(logError);
		return navigate(option.resource.url);
	}
	return change(option, { valid: true });
};

export const isKeycodeField = (name?: string) => name && name.indexOf('Key Code') !== -1;

export const openProductLinkInNewTab = (link: string) => window.open(link, '_blank');

export type Validator = {
	invalid: boolean;
	message?: string;
};

export type Validate = (...params: any[]) => Validator;
export type ValidatorHook = { validator: Validator; validate: Validate; status?: LazyQueryResult<any, any> };

export const useValidatorByName = (name: string): ValidatorHook => {
	const defaultValidator: Validator = { invalid: false, message: '' };
	const validatorHook = validatorHooks[`useValidate${pascalCase(name)}`];
	return validatorHook ? validatorHook(defaultValidator) : { validator: defaultValidator, validate: () => defaultValidator };
};

/**
 * Generates selector for a product option name.
 * Replaces double quotes with the word 'inch' and removes extraneous info in parens.
 *
 * @param {string} id - automation id
 */
export const generateProductOptionNameSelector = (id: string) => {
	return id
		.replace(/\s?\(.+?\)/, '')
		.split(' ')
		.join('-')
		.toLowerCase()
		.replace('"', '-inch');
};
